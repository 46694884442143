import { IonContent,IonButton, IonHeader, IonPage, IonTitle, IonToolbar,useIonToast,useIonActionSheet } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import HuddleUp from '../components/HuddleUp';
import './Tab1.css';

const Tab1: React.FC = () => {
 
  const [present, dismiss] = useIonActionSheet();
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>quickhuddles22222</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 1222222</IonTitle>
          </IonToolbar>
        </IonHeader>
        
         
        <HuddleUp name="Tab 1 page" />

      </IonContent>
    </IonPage>
  );
};

export default Tab1;
