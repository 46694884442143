import './ExploreContainer.css';

interface ContainerProps {
  name: string;
}

const HuddleUp: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div className="container" style={{
      backgroundColor: 'black'
    }}>
      <strong>{name}</strong>
      <p>GO JOE <a target="_blank" rel="noopener noreferrer" href="https://ionicframework.com/docs/components">Huddle Upe</a></p>
      <img src="https://beerroster.com/img/logo.png" />
    </div>
  );
};

export default HuddleUp;